<template>
  <div class="d-flex flex-row" id="kt_wrapper">
    <div
      class="flex-row-auto offcanvas-mobile w-250px w-xxl-300px"
      :class="{'offcanvas-mobile-on': subheaderMobileState}"
      id="kt_profile_aside"
      v-click-outside="handleFocusOut"
    >
      <transformers-list
        :filters="filters"
        :send-request-flag="searchClicked"
        :deselect-transformer="showTransformersTable"
        @selectTransformer="onTransformerSelect"
        @selectChild="onTransformerChildSelect"
      />
    </div>
    <div class="flex-row-fluid ml-lg-8">
      <app-card card-class="h-auto">
        <template #body>
          <div class="d-flex">
            <app-datepicker
              v-for="({ name, label, placeholder }, index) in datePickers"
              :key="index"
              class="w-50"
              :class="{'ml-5': index}"
              :label="$t(label)"
              :placeholder="$t(placeholder)"
              :value="filters[name]"
              @change="filters[name] = $event"
            />
          </div>
          <b-button variant="primary" :disabled="isSearchDisabled" @click="search">{{ $t('btn.search') }}</b-button>
        </template>
      </app-card>

      <app-card v-if="selectedTransformerId || selectedChildId" card-class="h-auto mt-5">
        <template #body>
          <div class="d-flex justify-content-around align-items-center">
            <div class="d-flex flex-column align-items-center">
              <div class="font-weight-bolder">{{ $t('label.kilowatt_transformers') }}</div>
              <div>{{ computedKilowattTransformersSummary || '-' }}</div>
            </div>
            <div class="d-flex flex-column align-items-center">
              <div class="font-weight-bolder">{{ $t('label.kilowatt_children') }}</div>
              <div>{{ computedKilowattChildrenSummary || '-' }}</div>
            </div>
            <div class="d-flex flex-column align-items-center">
              <div class="font-weight-bolder">{{ $t('label.kilowatt_counters') }}</div>
              <div>{{ computedKilowattCountersSummary || '-' }}</div>
            </div>
          </div>
        </template>
      </app-card>

      <transformers-table v-if="showTransformersTable" :update-table-flag="searchClicked" :filters="filters"/>
      <transformer-children-table v-if="showTransformerChildrenTable" :filters="childrenFilters"/>
      <transformer-child-counters-table v-if="showTransformerChildCountersTable" :filters="countersFilters"/>

      <portal to="subheader-toolbar">
        <b-button
          v-if="showExportBtn"
          class="mr-3"
          :class="{'cursor-not-allowed': exportBtnDisabled}"
          variant="outline-primary"
          :disabled="exportBtnDisabled"
          @click="exportItems"
        >
          {{ $t('btn.export') }}
        </b-button>
        <b-button
            v-if="this.showTransformersTable"
            class="mr-3"
            :class="{'cursor-not-allowed': exportBtnDisabled}"
            variant="outline-primary"
            :disabled="exportBtnDisabled"
            @click="exportMonth"
        >
          {{ $t('btn.export') }}
        </b-button>
      </portal>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AppCard from '@/components/AppCard';
import AppDatepicker from '@/components/form-groups/AppDatepicker.vue';
import TransformersList from './components/TransformersList.vue';
import TransformersTable from './components/TransformersTable.vue';
import TransformerChildrenTable from './components/TransformerChildrenTable.vue';
import TransformerChildCountersTable from './components/TransformerChildCountersTable.vue';

export default {
  components: {
    AppCard,
    AppDatepicker,
    TransformersList,
    TransformersTable,
    TransformerChildrenTable,
    TransformerChildCountersTable
  },
  data: () => ({
    searchClicked: false,
    exportBtnDisabled: false,
    showTransformersTable: false,
    showTransformerChildrenTable: false,
    showTransformerChildCountersTable: false,
    selectedTransformerId: null,
    selectedChildId: null,
    filters: {dateStart: null, dateEnd: null},
    datePickers: [
      {name: 'dateStart', label: 'label.start_date', placeholder: 'placeholder.choose_start_date'},
      {name: 'dateEnd', label: 'label.end_date', placeholder: 'placeholder.choose_end_date'},
    ],
  }),
  computed: {
    ...mapGetters({
      subheaderMobileState: 'subheaderMobileToggle',
      transformerSummary: 'transformersStore/SUMMARY',
      childSummary: 'transformersStore/CHILD_SUMMARY'
    }),
    isSearchDisabled() {
      return !this.filters.dateStart || !this.filters.dateEnd;
    },
    showExportBtn() {
      return this.showTransformersTable || this.showTransformerChildrenTable || this.showTransformerChildCountersTable;
    },
    childrenFilters() {
      return {...this.filters, transformer_id: this.selectedTransformerId};
    },
    countersFilters() {
      return {...this.filters, children_id: this.selectedChildId};
    },
    isTransformerSummary() {
      return !!Object.keys(this.transformerSummary).length;
    },
    isChildSummary() {
      return !!Object.keys(this.childSummary).length;
    },
    computedKilowattTransformersSummary() {
      let result = null;

      if (this.isChildSummary) {
        result = this.childSummary.kilowatt_transformers;
      } else if (this.isTransformerSummary) {
        result = this.transformerSummary.kilowatt_transformers;
      }

      return result;
    },
    computedKilowattChildrenSummary() {
      let result = null;

      if (this.isChildSummary) {
        result = this.childSummary.kilowatt_childrens;
      } else if (this.isTransformerSummary) {
        result = this.transformerSummary.kilowatt_childrens;
      }

      return result;
    },
    computedKilowattCountersSummary() {
      let result = null;

      if (this.isChildSummary) {
        result = this.childSummary.kilowatt_counters;
      } else if (this.isTransformerSummary) {
        result = this.transformerSummary.kilowatt_counters;
      }

      return result;
    }
  },
  watch: {
    searchClicked(val) {
      if (val) {
        setTimeout(() => {
          this.searchClicked = false;
        }, 500);
      }
    },
    selectedTransformerId(id) {
      console.log('selectedTransformerId');
      console.log(id);
      if (id) {
        this.$store.dispatch('transformersStore/CLEAR_CHILD_SUMMARY');
        this.$store.dispatch('transformersStore/GET_TRANSFORMER_SUMMARY', this.childrenFilters);
      } else {
        this.$store.dispatch('transformersStore/CLEAR_TRANSFORMER_SUMMARY');
      }
    },
    selectedChildId(id) {
      console.log('selectedChildId');
      console.log(id);
      if (id) {
        this.$store.dispatch('transformersStore/CLEAR_TRANSFORMER_SUMMARY');
        this.$store.dispatch('transformersStore/GET_CHILD_SUMMARY', this.countersFilters);
      } else {
        this.$store.dispatch('transformersStore/CLEAR_CHILD_SUMMARY');
      }
    }
  },
  methods: {
    handleFocusOut() {
      this.$store.commit('changeSubheaderMobileToggleState', false);
    },
    search() {
      this.showTransformersTable = true;
      this.showTransformerChildrenTable = false;
      this.showTransformerChildCountersTable = false;
      this.searchClicked = true;
    },
    onTransformerSelect(id) {
      this.selectedTransformerId = id;
      this.selectedChildId = null;
      this.showTransformerChildrenTable = !!id;
      this.showTransformersTable = !id;
      this.showTransformerChildCountersTable = false;
    },
    onTransformerChildSelect(id) {
      this.selectedChildId = id;
      this.selectedTransformerId = null;
      this.showTransformerChildCountersTable = true;
      this.showTransformersTable = false;
      this.showTransformerChildrenTable = false;
    },
    exportItems() {
      this.exportBtnDisabled = true;
      let action = '';
      let filters = null;

      if (this.showTransformersTable) {
        action = 'transformersStore/EXPORT_TRANSFORMERS';
        filters = this.filters;
      } else if (this.showTransformerChildrenTable) {
        action = 'transformersStore/EXPORT_CHILDREN';
        filters = this.childrenFilters;
      } else if (this.showTransformerChildCountersTable) {
        action = 'transformersStore/EXPORT_COUNTERS';
        filters = this.countersFilters;
      }

      if (action && filters !== null) {
        this.$store.dispatch(action, filters).then(() => this.exportBtnDisabled = false);
      }
    },
    exportMonth() {
      this.exportBtnDisabled = true;
      let action = '';
      let filters = null;

      if (this.showTransformersTable) {
        action = 'transformersStore/EXPORT_TRANSFORMERS_MONTH';
        filters = this.filters;
      }

      if (action && filters !== null) {
        this.$store.dispatch(action, filters).then(() => this.exportBtnDisabled = false);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cursor-not-allowed {
  cursor: not-allowed !important;
}
</style>