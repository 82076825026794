<template>
  <app-card card-class="h-auto mt-5">
    <template #body>
      <app-table
        :full-height="false"
        request-url="/api/transformers/childrens/table"
        :fields="tableFields"
        :filters="filters"
        :update-data="updateTableFlag"
      ></app-table>
    </template>
  </app-card>
</template>

<script>
import {mapGetters} from 'vuex';
import AppCard from '@/components/AppCard';
import AppTable from '@/components/lists/AppTable';

export default {
  components: {
    AppCard,
    AppTable,
  },
  props: {
    filters: {type: Object, default: () => {}}
  },
  data: () => ({
    updateTableFlag: false,
  }),
  computed: {
    tableFields() {
      return [
        {key: 'children_id', label: this.$t('label.child_id')},
        {key: 'name', label: this.$t('label.name')},
        {key: 'kilowatt', label: this.$t('label.kilowatt')},
        {key: 'kilowatt_counters', label: this.$t('label.kilowatt_counters')},
      ];
    },
  },
  watch: {
    updateTableFlag(val) {
      if (val) {
        setTimeout(() => {
          this.updateTableFlag = false;
        }, 500);
      }
    }
  }
}
</script>