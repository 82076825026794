<template>
  <app-card card-class="h-auto mt-5">
    <template #body>
      <app-table
        :full-height="false"
        request-url="/api/transformers/table"
        :fields="tableFields"
        :filters="filters"
        :update-data="updateTableFlag"
      ></app-table>
    </template>
  </app-card>
</template>

<script>
import AppCard from '@/components/AppCard';
import AppTable from '@/components/lists/AppTable';

export default {
  components: {
    AppCard,
    AppTable,
  },
  props: {
    filters: {type: Object, default: () => {}},
    updateTableFlag: {type: Boolean, default: false},
  },
  computed: {
    tableFields() {
      return [
        {key: 'transformer_id', label: this.$t('label.transformer_id')},
        {key: 'name', label: this.$t('label.name')},
        {key: 'kilowatt', label: this.$t('label.kilowatt')},
        {key: 'kilowatt_childrens', label: this.$t('label.kilowatt_children')},
        {key: 'kilowatt_counters', label: this.$t('label.kilowatt_counters')},
      ];
    },
  }
}
</script>