<template>
  <app-card :body-class="!transformersList.length ? 'd-flex justify-content-center align-items-center' : ''">
    <template #body>
      <div class="navi navi-bold navi-hover navi-active navi-link-rounded">
        <div class="navi-item mb-2">
          <div class="accordion" role="tablist">
            <div v-if="!isDataLoaded" class="text-muted">{{ $t('help.select_date') }}</div>
            <div v-else-if="transformersList.length">
              <b-card v-for="item in transformersList" :key="item.transformer_id" class="mb-1" no-body>
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle="`accordion-${item.transformer_id}`"
                    variant="outline-primary"
                    :ref="`transformer-${item.transformer_id}`"
                    @click="selectTransformer($event, item.transformer_id)"
                  >
                    {{ item.name }}
                  </b-button>
                </b-card-header>
                <b-collapse :id="`accordion-${item.transformer_id}`" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div
                      v-for="child in transformerChildrenList"
                      class="navi-link cursor-pointer py-4"
                      :class="{active: selectedChildId === child.children_id}"
                      @click="selectChild($event, child.children_id)"
                    >
                  <span class="navi-icon mr-2">
                    <span class="svg-icon">
                      <inline-svg src="/media/svg/icons/Media/Rec.svg"/>
                    </span>
                  </span>
                      <span class="navi-text font-size-lg" style="line-break: anywhere">{{ child.name }}</span>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <div v-else class="text-muted">{{ $t('table.no_data') }}</div>
          </div>
        </div>
      </div>
    </template>
  </app-card>
</template>
<script>
import {mapGetters} from 'vuex';
import AppCard from '@/components/AppCard.vue';

export default {
  components: {AppCard},
  props: {
    filters: {type: Object, default: () => {}},
    sendRequestFlag: {type: Boolean, default: false},
  },
  data: () => ({
    isDataLoaded: false,
    selectedTransformerId: null,
    selectedChildId: null,
    openedTransformer: null,
  }),
  computed: {
    ...mapGetters({
      transformersList: 'transformersStore/LIST',
      transformerChildrenList: 'transformersStore/CHILDREN_LIST'
    })
  },
  watch: {
    selectedTransformerId() {
      this.selectedChildId = null;
    },
    sendRequestFlag(val) {
      if (val) {
        if (!this.isDataLoaded) this.isDataLoaded = true;
        if (this.selectedTransformerId) {
          this.$refs[`transformer-${this.selectedTransformerId}`][0].click();
        }

        this.getList();
      }
    }
  },
  methods: {
    selectTransformer(e, transformerId) {
      this.selectedChildId = null;
      this.$store.commit('changeSubheaderMobileToggleState', false);

      if (!e.target.classList.contains('not-collapsed')) {
        this.selectedTransformerId = transformerId;
        this.$emit('selectTransformer', transformerId);
        this.getChildrenList(transformerId);
      } else {
        this.selectedTransformerId = null;
        this.$emit('selectTransformer', null);
      }
    },
    selectChild(e, childId) {
      if (!e.target.classList.contains('active')) {
        this.selectedChildId = childId;
        this.$emit('selectChild', childId);
      }
    },
    getList() {
      this.$store.dispatch('transformersStore/GET_LIST', this.filters);
    },
    getChildrenList(transformerId) {
      this.$store.dispatch('transformersStore/CLEAR_CHILDREN_LIST');
      this.$store.dispatch('transformersStore/GET_CHILDREN_LIST', {...this.filters, transformer_id: transformerId});
    }
  }
}
</script>